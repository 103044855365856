@font-face {
    font-family: 'gotham-bold';
    src: url('../fonts/Gotham-Bold.eot');
    src: local('Gotham-Bold'),
        url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Bold.woff2') format('woff2'),
        url('../fonts/Gotham-Bold.woff') format('woff'),
        url('../fonts/Gotham-Bold.ttf') format('truetype'),
        url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham-book';
    src: url('../fonts/Gotham-Book.eot');
    src: local('Gotham-Book'),
        url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Book.woff2') format('woff2'),
        url('../fonts/Gotham-Book.woff') format('woff'),
        url('../fonts/Gotham-Book.ttf') format('truetype'),
        url('../fonts/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.eot');
    src: local('Gotham-XLight'),
        url('../fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-XLight.woff2') format('woff2'),
        url('../fonts/Gotham-XLight.woff') format('woff'),
        url('../fonts/Gotham-XLight.ttf') format('truetype'),
        url('../fonts/Gotham-XLight.svg#Gotham-XLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLightItalic.eot');
    src: local('Gotham-XLightItalic'),
        url('../fonts/Gotham-XLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-XLightItalic.woff2') format('woff2'),
        url('../fonts/Gotham-XLightItalic.woff') format('woff'),
        url('../fonts/Gotham-XLightItalic.ttf') format('truetype'),
        url('../fonts/Gotham-XLightItalic.svg#Gotham-XLightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'gotham-light';
    src: url('../fonts/Gotham-Light.eot');
    src: local('Gotham-Light'),
        url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Light.woff2') format('woff2'),
        url('../fonts/Gotham-Light.woff') format('woff'),
        url('../fonts/Gotham-Light.ttf') format('truetype'),
        url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham-medium';
    src: url('../fonts/Gotham-Medium.eot');
    src: local('Gotham-Medium'),
        url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Medium.woff2') format('woff2'),
        url('../fonts/Gotham-Medium.woff') format('woff'),
        url('../fonts/Gotham-Medium.ttf') format('truetype'),
        url('../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
*, ::after, ::before {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: gotham-light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,Helvetica,Arial,Verdana,sans-serif;
    background-color: #f2f2f2;
}
h6 {
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: gotham,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji,Helvetica,Arial,Verdana,sans-serif;
    text-transform: none;
}
ul:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
}
dd, dl, menu, ol, ul {
    list-style: none;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 0px!important;
}
span {
    display: inline-block;
}

input, textarea {
  font-size: 16px!important;
  line-height: 21px!important;
}

.gotham-medium {
    font-family: gotham-book,Helvetica,Arial,Verdana,sans-serif;
    font-weight: 700;
}
.loader-wrapper {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    z-index: 9608;
}
.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /*mobile view styles start*/
  .mv {
    background-color: #f2f2f2;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .mv .search-bar {
    width: 100%;
  }

  .mv .search-bar.display-none {
    display: none;
  }

  .mv .search-bar .section-box {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 3px #f1f1f1 !important;
    margin: 15px 10px;
    padding: 15px 12px 15px 10px;
  }

  .mv .search-bar .section-flex {
    flex-direction: column;
    display: flex;
    text-align: left;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }

  .mv .search-bar .header {
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
    text-transform: none;
  }

  .mv .search-bar .location {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .mv .search-bar .input-label {
    color: #6e6e85;
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: 15px;
  }

  .mv .search-bar .input-text {
    height: 40px;
    color: #292929;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    border: 2px solid #777272;
    outline: none;
    border-radius: 8px;
    background: #f2f2f2;
    padding-left: 8px;
  }

  .mv .search-bar .input-text::placeholder {
    color: #9e9e9e;
    opacity: 1;
  }
  
  .mv .search-bar .input-text:-ms-input-placeholder {
    color: #9e9e9e;
  }
  
  .mv .search-bar .input-text::-ms-input-placeholder {
    color: #9e9e9e;
  }

  .mv .search-bar .distance {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  .width-full {
    width: 100% !important;
  }

  .mv .search-bar .distance-input {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
  }

  .mv .search-bar .button {
    height: 36px;
    color: #ffffff;
    font-size: 14px;
    line-height: 36px;
    font-weight: 400;
    text-align: center;
    background: #fd5a63;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 2px 0;
    outline: 0;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
  }

  .mv .search-bar .distance-button {
    margin-left: 10px!important;
  }

.mv .search-bar .in-active {
  background-color: #888!important;
}

.mv .search-bar .slider-box {
  padding: 15px 0px;
}

.mv .search-bar .distance-slider {
  -webkit-appearance: none;
  appearance: none;
  overflow: hidden;
  width: 100%;
  height: 18px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  -o-border-radius: 9px;
  border-radius: 9px;
}

.mv .search-bar .distance-slider::-moz-focus-outer {
  border: 0;
}

.mv .search-bar .distance-slider:focus {
  outline: none;
}

.mv .search-bar .distance-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  width: 100%;
  height: 18px;
  background-color: transparent;
  border: 2px solid #cacaca!important;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.mv .search-bar .distance-slider::-moz-range-track {
  width: calc(100% - 6px);
  height: 14px;
  background-color: transparent;
  border: 2px solid #cacaca!important;
  -moz-border-radius: 7px;
  border-radius: 7px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.mv .search-bar .distance-slider::-ms-track {
  width: 100%;
  height: 18px;
  background-color: transparent;
  color: transparent;
  border: 2px solid #cacaca!important;
  -ms-border-radius: 9px;
  border-radius: 9px;
  cursor: pointer;
  position: relative;
  z-index: 3;
}

.mv .search-bar .distance-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  background: #ff000d;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-color: #ff000d;
  margin-top: -2px;
  box-shadow: -1000px 0 0 990px #fd5a63;
  cursor: pointer;
}

.mv .search-bar .distance-slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #ff000d;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border-color: #ff000d;
  box-shadow: -1000px 0 0 990px #fd5a63;
  cursor: pointer;
}

.mv .search-bar .distance-slider::-ms-thumb {
  width: 18px;
  height: 18px;
  background: #ff000d;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border-color: #ff000d;
  box-shadow: -1000px 0 0 990px #fd5a63;
  cursor: pointer;
}

.mv .search-bar .search-btn {
  font-weight: 500;
}

.mv .search-info-section {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #ffffff;
}

.mv .search-query {
  width: 100%;
  background-color: #ffffff;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.mv .search-query .section-flex {
  display: flex;
  max-width: 100%;
  width: 100%;
  padding: 0px 20px;
}

.mv .search-query .location {
  font-weight: bold;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mv .search-query .distance {
  margin-left: auto;
  color: #898989;
  padding: 0px 10px;
  white-space: nowrap;
}

.mv .search-query .clear {
  margin-right: 10px;
  cursor: pointer;
}
  
.mv .search-tabs.section {
  width: 100%;
  padding: 0px 15px;
  margin: 0px auto;
  flex-wrap: wrap;
  flex: 1 0 100%;
  text-align: center;
}
  
.mv .search-tabs .tab-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px auto;
}
  
.mv .search-tabs .tab {
  width: 50%;
  cursor: pointer;
}
.mv .search-tabs .tab-deeplink {
  width: 100%;
  cursor: pointer;
}

.mv .search-tabs .tab-item {
  padding: 15px 8px 5px 8px;
  width: 100%;
}

.mv .search-tabs .tab-text {
  color: #5e5e79;
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 2px;
  margin-bottom: 0px;
}

.mv .search-tabs .active {
  color: #fd5a63;
  border-bottom: 3.5px solid #fd5a63;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.mv .result {      
  background: #f5f5f5;
  width: 100%;
  margin-top: 105px;
}

.mv .result .section {
  padding: 30px 20px;
}

.mv .result .list {
  border: 2px solid #d1d1dc;
  border-radius: 5px;
  background: #fff;
}

.mv .result .list-empty {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 10px 0;
}

.mv .result .trainer-flex {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 2px solid #d1d1dc;
}

.mv .result .trainer-flex:last-of-type {
  border-bottom: 0!important;
}
.mv .result .trainer-bx {
  width: 100%;
  padding: 10px 12px;
  display: flex;
  cursor: pointer;
}

.trainer-bx:hover {
  background-color: #eaeaea;
}

.mv .result .image {
  width: 55px;
  height: 55px;
  background-size: cover;
  background-repeat: no-repeat;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mv .result .image  img{
  border-radius: 10px;
}

.mv .result .empty-image {
  border: 2px solid #a18a9436;
  width: 100%;
  height: 100%;
  background: #ccc;
  border-radius: 10px;
}

.mv .result .more-box {
  width: 100%;
  margin: 12px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 100%;
}

.mv .result .more-text {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  padding-left: 12px;
}

.mv .result .info-box {
  display: flex;
  width: calc(100% - 55px);
  margin-left: 10px;
  align-items: center;
  justify-content: space-between;
}

.mv .result .name-box {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 55px);
}

.mv .result .name {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
  white-space: pre-wrap;
}

.mv .result .role {
  padding-top: 5px;
  font-weight: 400;
  font-size: 16px;
}

.mv .result .distance {
  font-size: 14px;
  color: #bababa;
}

.mv .result .center-flex {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 2px solid #d1d1dc;
}

.mv .result .center-flex:last-of-type {
  border-bottom: 0!important;
}

.mv .result .center-bx {
  width: 100%;
  padding: 15px;
  cursor: pointer;
}

.mv .result .center-bx:hover {
  background: rgb(234, 234, 234);
}

.mv .result .center-name {
  font-size: 14px;
  line-height: 20px;    
  font-weight: bold;
}

.mv .result .center-info-box {
  display: flex;
  flex-flow: column;
}

.mv .result .center-info {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
}

.mv .result .center-info-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;
  margin: auto 0;
  color: #5a5959;
}

.mv .result .center-info-text {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #615e5e;
  padding-left: 12px;
}

.mv .result ul.center-info-text {
  list-style: inside !important;
}

.mv .result ul.center-info-text li::marker {
  color: #fd5a63;
}

.mv .result .center-direction {
  width: 108px;
  color: #fd5a63;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-decoration: none;
  padding: 3px 5px;
  margin-top: 15px;
  margin-bottom: 5px;
  border: 2px solid #fd5a63;
  border-radius: 15px;
  cursor: pointer;
}

.mv .result .form {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  background: #f2f2f2;
  padding: 15px 10px 25px 15px;
}

.mv .result .form-title {
  color: #474747;
}

.mv .result .form-title span{
  font-weight: bold;
}

.mv .result .input-box {
  margin-top: 10.5px;
  display: flex;
  align-items: center;
}

.mv .result .input-field {
  width: calc(100% - 50px);
}

.mv .result .input-text-field {
  width: 100%;
  height: 33px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  color: #292929;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: transparent!important;
  appearance: none;
  outline: 0;
  vertical-align: baseline;
  margin-bottom: 3px;
  border-bottom: 2px solid #cacaca!important;
  margin-left: 10px;
}

.mv .result textarea.input-text-field {
  height: 45px;
}

.mv .result .input-text-field::placeholder {
  color: #9e9e9e;
  opacity: 1;
}

.mv .result .input-text-field:-ms-input-placeholder {
  color: #9e9e9e;
}

.mv .result .input-text-field::-ms-input-placeholder {
  color: #9e9e9e;
}

.mv .result .input-text-field .PhoneInputInput {
  border: 0;
  color: #292929;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: transparent!important;
  appearance: none;
  outline: 0;
  vertical-align: baseline;
  height: 100%;
}

.mv .result .PhoneInputInput::placeholder {
  color: #9e9e9e;
  opacity: 1;
}

.mv .result .PhoneInputInput:-ms-input-placeholder {
  color: #9e9e9e;
}

.mv .result .PhoneInputInput::-ms-input-placeholder {
  color: #9e9e9e;
}

.mv .result .input-checkbox {
  width: 100%;
  min-height: 20px;
  color: #9e9e9e;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
}

.mv .result .input-checkbox-icon {
  width: 20px;
  height: 20px;
  background: transparent;
  margin: 0;
}

.mv .result .input-checkbox-label {
  margin-left: 10px;
}

.mv .result .input-checkbox-label a{
  color: #fd5a63;
}

.mv .result .input-error {
  color: #fd5a63 !important;
  font-weight: 500;
  font-size: 11px;
  margin-left: 10px;
  line-height: 18px;
}

.mv .result .input-terms-error {
  color: #fd5a63 !important;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  margin-left: 10px;
}

.mv .result .submit-button {
  margin-top: 25px;
  margin-right: auto;
  flex-grow: 1;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  background: #fd5a63;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0;
  outline: 0;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  padding: 3px 20px;
}

.mv .introduction {
  width: 100%;
  padding: 10px 0px;
  background-color: #e9e9e9;
  font-size: 14px;
}

.mv .introduction .section {
  margin: 0px 20px;
}

.mv .introduction .header {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
}

.mv .introduction .expanded {
  margin-bottom: 5px;
}

.mv .introduction .title {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
}

.mv .introduction .intro-one {
  margin-bottom: 16px;
}

.mv .introduction .intro-three {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.mv .introduction .intro-five {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}
/*mobile view styles end*/

.header-section {
    box-shadow: 0 0 15px 1px rgb(0 0 0 / 7%);
    padding: 0 4px;
    max-width: 100%;
    background: #fff none repeat center center;
    background-size: auto;
    position: relative;
    z-index: 102;
    grid-area: header;
}
.header-bar {
    min-height: 100px;
    max-width: calc(1600px - 30px - 30px);
    margin: 0 auto;
    flex-flow: row nowrap;
}
.heartfulness-branding {
    line-height: 0px;
    font-size: 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.heartfulness-branding > a {
    padding: 0 5px 0 0;
    display: block;
    text-decoration: none;
    color: #fd5a63;
}
.heartfulness-logo-svg {
    overflow: hidden;
    width: 215px;
    vertical-align: middle;
}
.mobile-sticky-header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .4s;
    background-color: rgba(0,0,0,.5);
}
.horizontal-line{
    border-top: 1px solid #cccc;
}
.primary-menu {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: end;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
}
.primary-menu-item {
    position: relative;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    height: 100%;
}
.primary-menu-item > a {
    color: #333;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-flex-pack: center;
    -ms-justify-content: center;
    justify-content: center;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
    -ms-flex-line-pack: stretch;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-positive: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-flex-pack: center;
    -ms-justify-content: center;
    justify-content: center;
    margin-left: 0;
    margin: 18px 14px 18px 0px;
    padding: 4px 4px 4px 4px;
    position: relative;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
}
.primary-menu>li>a>span {
    position: relative;
    display: block;
}
.primary-menu>li>a>span>.menu-text {
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    text-transform: none;
    display: block;
}
.primary-menu>li>a:hover {
    color: #fd5a63!important;
}
.primary-menu-active {
    color: #fd5a63!important;
}
.primary-menu>li>a i.underline {
    bottom: -6px;
    background-color: #fd5a63;
    background: #fd5a63;
    background: -webkit-linear-gradient();
    background: linear-gradient();
    height: 2px;
    position: absolute;
    display: block;
    left: 1px;
    overflow: hidden;
    width: 0;
    pointer-events: none;
    background-color: transparent;
    opacity: .85;
    transition: all .3s cubic-bezier(.175,.885,.32,1.275);
}
.primary-menu>li>a i.underline:hover {
    width: 100%;
}
.primary-sub-menu {
    padding-left: 0;
    list-style-type: none;
    padding: 0px;
    background-color: #fff;
    box-shadow: 0 0 4px rgb(0 0 0 / 20%);
    box-sizing: border-box;
    position: absolute;
    z-index: 200;
    top: 100%;
    left: 0px;
    opacity: 1;
    visibility: visible;
    width: 280px;
}
.primary-sub-menu>li {
    position: relative;
    display: block;
    box-sizing: border-box;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
}
.primary-sub-menu>li>a {
    color: #333;
    margin: 0 0 0 0;
    padding: 10px 10px 10px 10px;
    transition: background .3s;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    text-decoration: none;
}
.primary-sub-menu>li>a:hover {
    color: #fd5a63!important;
}
.primary-sub-menu>li>a .menu-text{
    font-size: 13px;
    line-height: 17px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    text-transform: none;
    display: block;
}
.mobile-header-bar {
    margin: 0 auto;
    position: relative;
    min-height: 150px;
    width: 100%;
    -ms-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: justify;
}
.mobile-header-bar>* {
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.heartfulness-mobile-branding {
    line-height: 0px;
}
.heartfulness-mobile-branding > a {
    padding: 0px;
    display: block;
    text-decoration: none;
    color: #fd5a63;
}
.mobile-header-bar > * {
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.mobile-mini-widgets {
    max-width: calc(100% - 45px);
}
.dt-mobile-menu-icon {
    margin: 0 0 0 0;
    border-radius: 0;
    padding: 5px 10px 5px 10px;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    -ms-justify-content: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    position: relative;
    text-decoration: none;
}
.dt-mobile-menu-icon::before {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: inherit;
    box-sizing: border-box;
}
.dt-mobile-menu-icon::after {
    transition: 0.2s linear;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: inherit;
    box-sizing: border-box;
}
.lines-button {
    height: 16px;
    width: 22px;
    margin-right: 0;
    text-align: center;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
    flex-direction: column;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    -ms-justify-content: center;
    justify-content: space-evenly;
    cursor: pointer;
    user-select: none;
}
.menu-line {
    background: #333333!important;
    width: 22px;
    height: 2px;
    max-width: 100%;
    display: inline-block;
    width: 22px;
    height: 2px;
    content: '';
    transform-origin: 1.5px center;
    transition: transform .2s linear,color .2s linear;
}
.menu-line::before {
    background: #333333!important;
    transition: 0.2s linear;
    opacity: 1;
    width: 100%;
    height: 100%;
    content: "";
    border-radius: inherit;
    box-sizing: border-box;
}
.menu-line::after {
    width: 100%;
    height: 100%;
    content: "";
    border-radius: inherit;
    box-sizing: border-box;
    background: #333333!important;
    transition: 0.2s linear;
}
.mobile-header-sidebar {
    right: 300px;
    overflow-y: hidden;
    transform: translate3d(100%,0,0);
    transition: right .5s ease;
    display: block;
    background-color: #fff;
    width: 300px;
    position: fixed;
    top: 0;
    height: 100%;
    min-height: 100%;
    max-width: 100%;
    z-index: 9602;
    overflow: hidden;
}
.mobile-header-scrollbar-wrap {
    max-width: 287px;
    width: calc(300px - 13px);
    padding: 45px 15px 30px 30px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
}
.mobile-header-close-icon {
    right: 0;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    margin: 15px 0 0 0;
    border-radius: 0;
    transition: opacity .5s ease;
    min-width: 12px;
    min-height: 12px;
    justify-content: center;
    position: absolute;
    top: 0;
    z-index: 9999;
    align-items: center;
    display: inline-flex;
    background-color: #fd5a63;;
}
.close-line-wrap {
    width: 25px;
    height: 25px;
    position: relative;
    
    display:inline-block;
    color: rgba(51,51,51,.17);
    
    background:
      linear-gradient(white, white),
      linear-gradient(white,white),
      #fd5a63;
    background-position:center;
    background-size: 50% 2px,2px 50%;
    background-repeat:no-repeat;
    cursor: pointer;
    transform: rotate(45deg);
}
.mobile-menu {
    border-radius: 1px;
    padding-left: 0;
    list-style-type: none;
    display: flex;
    flex-flow: column nowrap;
    flex: 10 1 auto;
}
.mobile-menu-item {
    display: flex;
    position: relative;
    flex-flow: row wrap;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    border-bottom: 1px solid #d9d9d9;
    min-height: 60px;
}
.mobile-menu>li>a {
    color: #333;
    text-decoration: none;
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;
}
.mobile-menu>li>a>span {
    position: relative;
    display: block;
}
.mobile-menu>li>a .menu-text {
    color: #333;
    font-weight: normal;
    font-size: 17px;
    line-height: 21px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    text-transform: none;
    display: block;
}
.next-level-button {
  display:inline-block;
  width:30px;
  height:30px;
  color: rgba(51,51,51,.17);
  background:
    linear-gradient(rgba(51,51,51,.17), rgba(51,51,51,.17)),
    linear-gradient(rgba(51,51,51,.17),rgba(51,51,51,.17)),
    white;
  background-position:center;
  background-size: 50% 2px,2px 50%;
  background-repeat:no-repeat;
  cursor: pointer;
  margin: 15px;
}
.next-level-button.active {
    transform: rotate(45deg);
    margin: 10px;
}
.mobile-sub-menu {
    padding-left: 20px;
    list-style-type: none;
    width: 100%;
}
.mobile-sub-menu>li {
    padding-left: 15px;
}
.mobile-sub-menu>li>a {
    color: #333;
    margin: 0 0 0 0;
    padding: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    text-decoration: none;
}
.mobile-sub-menu>li>a .menu-text{
    color: #333;
    font-size: 13px;
    line-height: 17px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    text-transform: none;
    display: block;
}
.map-section {
    text-align: center;
}
.introduction-title {
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: 30px;
}
.heartspot-introduction-one {
    margin-top: 0px;
    margin-bottom: 10px;
}
.heartspot-introduction-two {
    margin-top: 0px;
    margin-bottom: 10px;
    padding-bottom: 27px;
}
.heartspot-introduction-three {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.heartspot-final-introduction-one {
    margin-top: 0px;
    margin-bottom: 10px;
}
.heartspot-final-introduction-two {
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 10px;
}
.heartspot-final-introduction-three {
    font-family: gotham-bold, Helvetica, Arial, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 10px;
}
.searchStrip-Section {
    background: #f2f2f2;
    padding-bottom: 25px;
}
.main-Section {
    background: #f5f5f5;
}
.trainersAndCentersTabs-Section {
    width: 100%;
    padding: 0px 15px;
    margin: 0px auto;
    flex-wrap: wrap;
    flex: 1 0 100%;
    text-align: center;
}
.trainersAndCentersTabs-FlexBox {
    flex-wrap: wrap;
    display: flex;
    flex: 1 0 100%;
    text-align: center;
}
.trainersAndCentersTabs-List {
    display: -webkit-inline-box;
    border: 0px;
    text-align: center;
    padding-left: 0;
    list-style-type: none;
    list-style: none;
    flex-wrap: wrap;
    margin: 0px auto;
}
.trainersAndCentersTab-ListItem {
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 300;
    width: auto;
    cursor: pointer;
}
.trainersAndCenters-Tab {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    padding: 15px 8px 5px 8px;
}
.trainersAndCentersTab-Text {
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    padding-bottom: 8px;
    margin-bottom: 0px;
    line-height: 30px;
    font-weight: 500;
}
.trainersAndCentersTab-Active {
    color: #1ca0f2;
    border-bottom: 2px solid #1ca0f2;
}
.main-Section-FlexBox {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
}
.main-Section-FlexBox-deeplink {
    flex-direction: row;
    box-sizing: border-box;
    /* display: flex; */
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
}
.results-introduction-box {
    margin-top: 35px;
    margin-bottom: 50px;
    padding: 0px 15px;
}
.results-introduction {
    display: flex;
    flex-wrap: wrap;
}
.trainersAndCenters-Box {
    flex: 0 0 100%;
    width: 100%;
    padding: 0px 15px;
}
.introduction-FlexBox {
    padding: 0px 15px;
    padding-top: 20px;
    display: flex;
    flex: 0 0 100%;
    width: 100%;
}
.introduction-mobile-full {
    padding: 0px 15px !important;
    padding-top: 20px !important;
    flex: 0 0 100% !important;
    width: 100% !important;
}
.Introduction-Box {
    flex: 1.5 1 0%;
}
.finalIntroduction-FlexBox {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
}
.final-introduction-mobile-box {
    width:100%;
    padding-right: 15px;
    padding-bottom: 1rem!important;
    padding-top: 1rem!important;
}
.introduction-AppLink-FlexBox {
    width:100%;
    padding-right: 15px;
    padding-bottom: 1rem!important;
    padding-top: 1rem!important;
}
.introduction-AppLink-Image {
    width: 130px;
    padding-right: 4.5px;
    padding-left: 4.5px;
}
.introduction-Mobile-Box {
    width: 100%;
}
.introduction-Mobile-Image {
    width: 100%;
}
.footer-section {
    background: #1a1c20 none repeat center top;
    grid-area: footer;
}
.footer-bottom-bar {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    z-index: 9;
    margin: 0 auto;
    color: #fff;
}
.footer-bottom-box {
    padding: 0 25px 0 0;
    width: 1600px;
    margin: 0 auto;
    max-width: 100%;
}
.footer-bottom-flex-box {
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    box-sizing: border-box;
    min-height: 60px;
    border-top: 1px solid rgba(255,255,255,.12);
    padding: 15px;
}
.footer-bottom-float-left {
    margin-right: 10px;
    float: left;
}
.searchInput-TextBox {
    border: 0;
    border-bottom: 2px solid #000!important;
    border-radius: 0;
    outline: none;
    background: transparent;
    font-style: italic;
    border-style: initial;
    color: #888;
    font-size: 14px;
    background: #f2f2f2;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    width: 100%;
    height: 38px;
    margin-bottom: 20px;
}
.searchInput-TextBox:focus::-webkit-input-placeholder {
    opacity: 0.2;
}
.header-HeartSpot {
    text-align: center;
    font-size: 46px;
    color: #666;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    line-height: 55px;
    padding: 40px 0px 25px;
    box-sizing: border-box;
    font-weight: 500;
    margin: 0px 0px 10px 0px;
}
.header-HeartSpot-Span {
    color: #e40e6f;
}
.searchStrip-FlexBox {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    text-align: left;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: auto;
    flex-wrap: wrap;
    width: 100%;
}
.searchLocation-FlexBox {
    flex-direction: column;
    margin-bottom: 15px;
    box-sizing: border-box;
    display: flex;
    padding: 0px 15px;
    width: 100%;
}
.searchLocation-Label {
    color: #666;
    font-family: gotham-bold, Helvetica, Arial, Verdana, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 9px;
}
.searchDistance-FlexBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.searchWithin-FlexBox {
    flex-direction: row;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 0px 15px;
    flex: 0 0 80%;
    max-width: 80%;
    justify-content: space-between;
}
.searchWithin-Label {
    color: #666;
    font-family: gotham-bold, Helvetica, Arial, Verdana, sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 9px;
}
.search-miles-input-textbox {
    border: 0;
    border-bottom: 2px solid #000!important;
    border-radius: 0;
    outline: none;
    background: transparent;
    font-style: italic;
    border-style: initial;
    color: #888;
    font-size: 14px;
    background: #f2f2f2;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    height: 38px;
}
.search-distance-unit-box {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    outline: 0;
    border: none;
    background: transparent;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 30px;
    flex: 0 0 20%;
    max-width: 20%;
    color: #666;
    font-family: gotham-bold, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
}
.search-distance-unit-box > option {
    outline: 0;
    border: none;
    background: rgb(234, 234, 234);
}
.searchSlider-Box {
    box-sizing: border-box;
    width: 100%;
    padding: 0px 15px;
    padding-top: 24px;
    display:flex;
    width:100%;
}
.searchInput-Slider {
    border: 0;
    border-radius: 0;
    border-bottom: 0px;
    margin: 18px 0px;
    line-height: 30px;
    width: 100%;
    height: 38px;
    vertical-align: bottom;
    box-shadow: none;
}
.searchButton-FlexBox {
    align-items: center;
    margin-bottom: 15px;
    box-sizing: border-box;
    display: flex;
    padding-top: 18px;
    width: 100%;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    padding-right: 15px;
    flex-wrap: wrap;
    justify-content: center;
}
.search-button {
    padding: 0px 12px;
    align-items: center;
    outline: 0;
}
.searchButton {
    height: 30px;
    width: 110px;
    cursor: pointer;
    outline: 0;
    vertical-align: middle;
    text-align: center;
}
.searchButton-search {
    background: #fd5a63;
    color: #ffffff;
    text-transform: uppercase;
    user-select: none;
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    box-shadow: 0 2px 2px 0;
    box-sizing: border-box;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.searchButton-search:hover {
    background: rgb(118,118,118);
}
.clear-button {
    padding: 0px 12px;
}
.searchButton-Clear {
    background: transparent;
    color: #666;
    height: 36px;
    line-height: 36px;
    padding: 0 2rem;
    border: none;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-size: 18px;
    font-weight: 400;
}
.trainer-result-title {
    font-size: 16px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    text-align: center;
    line-height: 26px;
}
.trainer-result-title span {
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    padding-right: 4px;
    font-size: 17px;
    color: #000;
    font-weight: 500;
}
.trainers-list {
    background: #fff;
    height: 560px;
    overflow: scroll;
    scroll-behavior: smooth;
    overflow-x: hidden;
    border: 1px solid #e3e3e3;
}
.trainers-list::-webkit-scrollbar-thumb {
    background: #dc3582;
}
.trainers-list::-webkit-scrollbar {
    width: 8px;
}
.trainer-list-empty {
    line-height: 24px;
    color: #333333;
    margin-bottom: 10px;
    margin-top: 0px;
    clear: both;
    font-size: 16px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    text-align: center;
}
.more-button-box {
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.more-button {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    -webkit-appearance: button;
    color: #fd5a63;
    border: 2px solid #fd5a63;
    background-color: inherit;
    font-size: 16px;
    line-height: 20px;
    padding: 6px 12px;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    margin-bottom: 20px;
    max-width: 100%;
    height: 36px;
}
.trainer-FlexBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.trainer-box {
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    width: 100%;
    cursor: pointer;
}
.trainer-box:hover {
    background: rgb(234, 234, 234);
}
.global-guide-trainer-box {
    background-color: #e7e2b1a1;
}
.global-guide-trainer-box:hover {
    background-color: #c9c994a1 !important;
}
.trainer-form {
    padding: 15px;
    width: 100%;
    background: rgb(234, 234, 234);
    padding: 0px 15px 0px;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 300;
}
.trainer-form-title {
    padding-top: 15px;
    font-size: 14px;
    line-height: 20px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    text-align: left;
    color: #333333;
    text-transform: none;
    width: 100%;
}
.global-guide-form-title {
    padding-bottom: 5px;
}
.global-guide-form-title a {
    color: #4a90e2;
    font-weight: normal;
}
.trainer-form-title span{
    font-size: 14px;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    padding-right: 4px;
    font-weight: 500;
}
.trainerAndCenter-BGImage {
    width: 55px;
    height: 55px;
    background-size: cover;
    background-repeat: no-repeat;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
};
.trainer-BGImage {
    background-image: url(../images/heartspots-color.png);
}
.no-trainer-image {
    border: 2px solid #a18a9436;
    width: 100%;
    height: 100%;
    background: #ccc;
}
.trainerIndex-Span {
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 700;
}
.trainer-info-box {
    display: flex;
    width: calc(100% - 55px);
}
.trainerInfo-Box {
    margin-left: 10px;
}
.trainer-name {
    margin: 0;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
}
.trainer-role {
    padding-top: 5px;
    font-weight: 600;
    font-family: gotham;
    font-size: 16px;
}
.trainer-mail-address {
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    color: #777777;
    font-size: 15px;
    line-height: 15px;
}
.trainerMiles-Box {
    margin-left: auto;
    align-self: center;
}
.trainerMiles-Box > span {
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-size: 12px;
}
.center-result-title {
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    padding-right: 4px;
    font-size: 17px;
    text-align: center;
    line-height: 26px;
    color: #000;
    padding-right: 4px;
    font-weight: 500;
}
.centers-list {
    background: #fff;
    height: 560px;
    overflow-x: hidden;
    border: 1px solid #e3e3e3;
}
.centers-list::-webkit-scrollbar-thumb {
    background: #dc3582;
}
.centers-list::-webkit-scrollbar {
    width: 8px;
}
.center-list-empty {
    line-height: 24px;
    color: #333333;
    margin-bottom: 10px;
    margin-top: 0px;
    clear: both;
    font-size: 16px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    text-align: center;
}
.center-FlexBox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.center-box {
    border-bottom: 1px solid lightgray;
    padding: 15px;
    display: flex;
    width: 100%;
    cursor: pointer;
}
.center-box:hover {
    background: rgb(234, 234, 234);
}
.center-BGImage {
    background-image: url(../images/heartspots-black.png);
}
.centerIndex-Span {
    margin-bottom: 10px;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 700;
}
.centerInfo-Box {
    padding-left: 10px;
    text-align: left;
    float: left;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 300;
    width: calc(100% - 50px);
    display: flex;
    flex-direction: column;
}
.centerName {
    font-size: 20px;
    line-height: 30px;
    margin: 0px;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    color: #000;
    margin-bottom: 10px;
    font-weight: 500;
}
.center-address {
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    color: #000;
    font-size: 16px;
    line-height: 24px;
}
.center-timing {
    display: flex;
    flex-flow: column nowrap;
    margin: 15px 0;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}
.timing-list {
    margin-left: 10px;
    list-style: inside !important;
}
.center-email {
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}
.center-phone {
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;   
    line-height: 24px; 
}
.center-directions {
    color: #fd5a63;
    background-color: transparent;
    font-family: gotham-medium, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
    font-size: 15px;
    text-decoration: none;
    line-height: 24px;
    cursor: pointer;
    margin: 1em 0;
}
.section-Wrapper {
    margin: 0 auto;
}
.mapAutoComplete-Input {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 240px;
    height: 32px;
    padding: 0 12px;
    border-radius: 3px;
    box-sizing: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    position: absolute;
    left: 50%;
    margin-left: -120px;
}
.trainer-form-input-box {
    margin-top: 10.5px;
    display: flex;
}
.user-circle-icon {
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
}
.trainer-form-input-field {
    position: relative;
    text-align: left;
    width:100%;
}
.trainer-form-input {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background: transparent;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    border-style: initial;
    background-color: transparent!important;
    appearance: none;
    outline: 0;
    vertical-align: baseline;
    width: calc(100% - 3rem);
    margin-bottom: 3px;
    border-bottom: 1px solid #000!important;
    margin-left: 24px;
    height: 38px;
}
.trainer-form-input .PhoneInputInput {
    border: 0px;
    background-color: transparent!important;
    color: #000;
    font-size: 16px;
    font-weight: normal;
    appearance: none;
    outline: 0;
    vertical-align: baseline;
    height: 100%;
}
.trainer-form-message-input {
    height: 48px;
    margin-top: 10px;
    font-size: 16px;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    font-weight: 400;
}
.trainer-form-input:focus {
    border-bottom-width: 2px !important;
}
.trainer-form-input-error {
    top: 26px;
    color: #fd5a63 !important;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    font-size: 11px;
    margin-left: 24px;
    line-height: 24px;
}
.trainer-form-input-terms-error {
    top: 26px;
    color: #fd5a63 !important;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    font-weight: normal;
    font-size: 11px;
    margin-left: 35px;
    line-height: 24px;
}
.trainer-form-checkbox-box {
    width: 100%;
    margin-top: 10px;
    left: auto;
    right: auto;
    min-height: 1px;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    display: grid;
    text-align: left;
    margin-bottom: 36px;
}
.trainer-form-checkbox {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background: transparent;
    color: #000;
    font-size: 16px;
    font-family: gotham-book, Helvetica, Arial, Verdana, sans-serif;
    width: 20px;
    height:20px;
    margin-left: 0;
}
.trainer-form-checkbox-label {
    font-size: 15px !important;
    color: #333;
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    user-select: none;
}
.trainer-form-checkbox-label a{
    color: #fd5a63;
}
.trainer-form-show-map {
    font-size: 11px;
    text-decoration: none;
    color: #fd5a63;
    font-weight: bold;
}
.trainer-form-show-map:hover {
    color: #d62a32;
}
.trainer-form-submit-box {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
    min-height: 1px;
    float: left;
    flex-grow: 1;
    font-family: gotham-light, Helvetica, Arial, Verdana, sans-serif;
    max-width: 100%;
    font-weight: 300;
}
.trainer-form-submit-button {
    margin-bottom: 20px;
    float: left;
    cursor: pointer;
    border: none;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    -webkit-appearance: button;
    background: #fd5a63;
    color: #ffffff;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
}
.info-text-gotham {
    color: #333333;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: gotham,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji,Helvetica,Arial,Verdana,sans-serif;
    text-transform: none;
}
@media only screen and (min-width:576px) {
    .main-Section-FlexBox,.searchStrip-FlexBox, .trainersAndCentersTabs-Section {
        max-width: 540px;
    }
    .search-button {
        width: auto;
    }
     
}
@media only screen and (min-width:768px) {
    .main-Section-FlexBox,.searchStrip-FlexBox, .trainersAndCentersTabs-Section {
        max-width: 720px;
    }
    .trainersAndCenters-Box {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .trainersAndCenters-Box-deeplink {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .introduction-FlexBox {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 27px 15px;
    }
    .searchLocation-FlexBox {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .searchInput-TextBox {
        margin-bottom: 0px;
    }
    .searchDistance-FlexBox {
        flex: 0 0 41.67%;
        max-width: 41.67%;
    }
    .searchButton-FlexBox {
        flex: 0 0 25%;
        max-width: 25%;
        justify-content: normal;
    }
    .search-distance-unit-box {
        flex: 0 0 21.67%;
        max-width: 21.67%;
    }
    .searchSlider-Box {
        flex: 0 0 53.33%;
        max-width: 53.33%;
    }
    .trainersAndCenters-Tab {
        padding: .5rem 1rem;
        padding-top: 15px;
    }
    .searchWithin-FlexBox {
        flex: 0 0 25%;
        max-width: 25%;
        flex-direction: column;
        justify-content: normal;
    }
    .introduction-AppLink-FlexBox {
        -ms-flex: 0 0 58.33%;
        flex: 0 0 56.33%;
        max-width: 56.33%;
    }
    .introduction-Mobile-Box {
        -ms-flex: 0 0 41.67%;
        flex: 0 0 43.67%;
        max-width: 43.67%;
    }
    .search-button {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .trainer-result-title {
        text-align: left;
    }
    .center-result-title {
        text-align: left;
    }
    .trainersAndCentersTab-Text {
        font-size: 16px;
    }
}
@media only screen and (min-width:992px) {
    .main-Section-FlexBox,.searchStrip-FlexBox, .trainersAndCentersTabs-Section {
        max-width: 960px;
    }
    .search-button {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (min-width: 1051px)
{
    .search-distance-unit-box {
        flex: 0 0 16.67%;
        max-width: 16.67%;
    }
    .searchSlider-Box {
        flex: 0 0 58.33%;
        max-width: 58.33%;
    }
    .header-section {
        top: 0!important;
    }
    .header-bar {
        padding-right: 30px;
        padding-left: 30px;
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
        -webkit-align-items: stretch;
        -moz-align-items: stretch;
        -ms-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-align-content: stretch;
        -moz-align-content: stretch;
        -ms-align-content: stretch;
        align-content: stretch;
        -ms-flex-line-pack: stretch;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-flex-pack: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: justify;
    }
    .primary-menu {
        -webkit-align-items: stretch;
        -moz-align-items: stretch;
        -ms-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-align-content: stretch;
        -moz-align-content: stretch;
        -ms-align-content: stretch;
        align-content: stretch;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        -moz-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .mobile-header-bar {
        display: none;
    }
    .mobile-header-sidebar {
        display: none;
    }
}
@media only screen and (min-width:1200px) {
    .main-Section-FlexBox,.searchStrip-FlexBox, .trainersAndCentersTabs-Section {
        max-width: 1140px;
    }
    .search-button {
        width: auto;
    }
}
@media screen and (max-width: 1050px) {
    .mobile-header-bar {
        margin: 0 auto;
        -ms-flex-pack: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: start;
        padding: 0 30px 0 30px;
        box-sizing: border-box;
        min-height: 80px;
        display: -ms-flexbox;
        display: -ms-flex;
        display: flex;
    }
    .header-bar {
        display: none;
    }
    .heartfulness-mobile-branding {
        -ms-flex-pack: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start;
        -ms-flex-pack: start;
        -ms-flex-order: 0;
        order: 0;
    }
    .mobile-mini-widgets {
        -ms-flex-order: 1;
        order: 1;
        -ms-flex-pack: flex-end;
        -ms-justify-content: flex-end;
        justify-content: flex-end;
        -ms-flex-pack: end;
        -ms-flex: 1 1 0%;
        flex: 1 1 0%;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .mobile-navigation {
        -ms-flex-order: 2;
        order: 2;
    }
    .mobile-sticky-header-overlay.active {
        opacity: 1;
        visibility: visible;
        z-index: 9601;
    }
}
@media only screen and (max-width: 992px) {
    .trainer-form-input {
        width: 86%;
        width: calc(100% - 3rem);
    }
}
@media only screen and (max-width: 991px) {
    .trainerIndex-Span {
        font-size: 14px;
        line-height: 38px;
    }
    .centerIndex-Span {
        font-size: 14px;
        line-height: 38px;
    }
    .trainer-form-input {
        margin-bottom: 36px !important;
    }
    .heartspot-location {
        margin-bottom:35px !important;
    }
}
@media only screen and (max-width: 990px)
{
    .footer-bottom-flex-box {
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        -ms-flex-pack: center;
        -ms-justify-content: center;
        justify-content: center;
    }
    .footer-bottom-float-left {
        display: block;
        float: none;
        width: auto;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
        text-align: center!important;
    }
}
@media only screen and (max-width: 778px)
{
    .footer-bottom-box {
        padding: 0 20px 0 20px;
    }
}
@media only screen and (max-width: 600px) {
    .trainer-form-input {
        width: 80%;
        width: calc(100% - 3rem);
    }
}
@media screen and (min-width: 779px) and (max-width: 1050px) {
    .mobile-navigation {
        margin-left: 10px;
    }
}
a{
    cursor: pointer;
}